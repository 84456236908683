import React, { useState, useEffect, useRef } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import NotificationAlert from "react-notification-alert";
import ChatWindow from 'components/ChatWindow';




const ShippingSignalRClient = (props) => {
    const [connection, setConnection] = useState(null);
    const [chat, setChat] = useState([]);
    const latestChat = useRef(null);
    const [connectionId, setConnectionId] = useState(null);
    const notificationAlertRef = React.useRef(null);

    latestChat.current = chat;


     const getConnectionId = (user) => {
        connection.invoke('getconnectionid',user).then(
            (data) => {
                console.log(data);
                setConnectionId(data);
            }
        );
}


    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_BASE_URL + '/shippinghub')
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    console.log('Connected!');
                    props.OnConnectionChanged(true); // notify UI

                    connection.on('ReceivePrivateMessage', (user, data) => {
                        console.log("ReceivePrivateMessage", data);

                        // message is prbobably a json object
                        let jsonData = JSON.parse(data);

                        let options = {};
                        options = {
                            place: "tr",
                            message: user + " : " + jsonData.message,
                            type: "success",
                            icon: "tim-icons icon-bell-55",
                            autoDismiss: 3,
                        };
                        notificationAlertRef.current.notificationAlert(options);

                        const updatedChat = [...latestChat.current];
                        let dataTemp = { "user": user, "message": jsonData.message };
                        updatedChat.push(dataTemp);
                        setChat(updatedChat);

                        // pass the data object (string format) to the UI
                        props.OnDataReceived(data);

                    });

                    // ReceiveData
                    connection.on('ReceiveData', (user, data) => {
                        console.log("ReceiveData", user, data);
                    });

                    // handle closed event
                    connection.onclose(async () => {
                        props.OnConnectionChanged(false); // notify UI
                        console.log('SignalR Connection Closed'); 
                    });
                })
                .then(() => getConnectionId(localStorage.getItem("system.station.shippingStationId") + "_WEBUI"))
                .catch(e => {
                    props.OnConnectionChanged(false); // notify UI
                    console.log('Connection failed: ', e);
                });
        }
    }, [connection]);

    const sendMessage = async (user, message) => {
        const chatMessage = {
            user: user,
            message: message
        };

        if (connection.connectionStarted) {
            try {
                await connection.send('SendMessage', chatMessage);
            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            alert('No connection to server yet.');
        }
    }

   

    return (
        <div>
            <hr />
            <ChatWindow chat={chat} />
            <div className="rna-container">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
        </div>
    );
};

export default ShippingSignalRClient;