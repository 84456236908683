


/*
Date Placed
    o	Date “Quote Converted to Order”

Acct. Duration
    o	Number of days between “Quote Converted to Order” and date Order is pushed into Submitted to Manufacturing

Date Pushed to Manufacturing
    o	Date Order is pushed to “Submitted to Manufacturing”

Config Duration.
    o	Number of days between “Submitted to Manufacturing” and order pushed into “In Production”

Date Pushed to Floor
    o	Date order is pushed into “In Production”
	
Floor Duration
    o	Number of days between Date Pushed to floor and the current date (or date completed)
	
Total Days in Mfg.
    o	Config. Duration + Floor Duration
*/


import React, { useEffect, useState } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

// reactstrap components
import {
    Button, Card,
    CardBody,
    CardFooter, CardHeader,
    CardTitle,
    Col,
    Form, FormGroup,
    Input,
    Label, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Progress,
    Row
} from "reactstrap";
import MfgApiService from "../MfgApiService";
import ReactTable from "../components/ReactTable/ReactTable";
import Common from "../utils/Common.js";


function getCustomer(data) {

    if (data['dealer'] !== "") {
        return data['dealer'];
    }
    else if (data['distributor'] !== "") {
        return data['distributor'];
    }
    else if (data['representative'] !== "") {
        return data['representative'];
    }
    else
        return "error";
}

// need to put in a common file
const ORDER_STATUS_BITS = {

    ORDER_ACTIVE: 0x0001,
    WOPR_PRINTED: 0x0002,
    SAW_CUT_COMPLETE: 0x0004,
    FABRIC_CUT_COMPLETE: 0x0008,
    ASSEMBLY_COMPLETE: 0x0010,
    PROVISION_COMPLETE: 0x0020,
    PUSHED_TO_DASHBOARD: 0x0040,
    SLEEVE_COMPLETE: 0x0080,
    SHADES_BOXED: 0x0100,
    ACCESSORIES_BOXED: 0x0200,
    SHIPPED: 0x0400,
    HARDWARE_BOXED: 0X0800,
    FASCIA_BOXED: 0x1000,
    ORDER_ABORTED: 0x040000000,
};

function statusConverter(status) {

    if (status & ORDER_STATUS_BITS.ORDER_ABORTED)
        return ["Aborted", "100", "progress-aborted"];

    if (status & ORDER_STATUS_BITS.SHIPPED)
        return ["Shipped", "100", "progress-shipped"];

    if (status & ORDER_STATUS_BITS.PROVISION_COMPLETE)
        return ["Provisioned", "50", "progress-primary"];

    if (status & ORDER_STATUS_BITS.SHADES_BOXED)
        return ["Shades Boxed", "75", "progress-primary"];

    return ["Pending", "25", "progress-info"];
}

const ProductionLog = () => {


    const [loading] = useState(true);
    const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("user")));
    const [tableColumns, setTableColumns] = useState([]);
   
    const [productionLog, setProductionLog] = useState([]);

    const buildColumns = () => {
        let myColumns =
            [
                {
                    Header: "Quote Id",
                    accessor: "quote_id"
                },
                {
                    Header: "Title",
                    accessor: "title",
                    width: 400
                },
                {
                    Header: "Date Placed",
                    accessor: "orderApprovedTime"
                },
                {
                    Header: "Acct. Duration",
                    accessor: "acctDuration"
                },
                {
                    Header: "Date Pushed to Mfg",
                    accessor: "datePushedToMfg"
                },
                {
                    Header: "Config Duration",
                    accessor: "configDuration"
                },
                {
                    Header: "Date Pushed to Floor",
                    accessor: "datePushedToFloor"
                },
                {
                    Header: "Floor Duration",
                    accessor: "floorDuration"
                },
                {
                    Header: "Total Days in Mfg",
                    accessor: "totalDaysInMfg"
                },
                {
                    Header: "Completed",
                    accessor: "orderCompleteTime"
                }
            ];

        return myColumns;
    }

    const populateData = () => {

        let order = async () => {

            
            const tokenString = localStorage.getItem("auth.access_token");
            const accessToken = JSON.parse(tokenString);
            const response = await fetch('api/orders/mfgstatus', {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                }
            });
            const data = await response.json();
            //console.log(data);

            //change the unix timestamps to human readable. And do other things too.
            let dataOrders = data.map(d => {

                let invalidData = false;
    
                let orderApprovedTime = new Date(d.order_approved_time * 1000);
                let sentToProductionTime = new Date(d.sent_to_production_time * 1000);
                let pushedToFloorTime = new Date(d.time_sent_to_floor * 1000);
                let todayTime = new Date();
                
                // Acct. Duration
               
                let acctDuration = Common.dateDiffInDays(orderApprovedTime, sentToProductionTime);

                // pushed to manufacturing
                let datePushedToMfg = Common.convertUnixDateToFriendlyDate(d.sent_to_production_time);

                // config duration
                let configDuration = Common.dateDiffInDays(sentToProductionTime, pushedToFloorTime);

                // pushed to floor
                let datePushedToFloor = Common.convertUnixDateToFriendlyDate(d.time_sent_to_floor);


               // let shipped = (d.orderMfgStatus & ORDER_STATUS_BITS.SHIPPED);

                // floor duration
                let floorDuration = 0;

                // need to factor in the date completed here... once we get a database value
                if (d.order_complete_time)
                {
                    let completeTime = new Date(d.order_complete_time);
                    floorDuration = Common.dateDiffInDays(pushedToFloorTime, completeTime);
                }
                else {
                    
                    floorDuration = Common.dateDiffInDays(pushedToFloorTime, todayTime);
                }
                
                // total days in mfg
                let totalDaysInMfg = configDuration + floorDuration;

                
                invalidData = (configDuration < 0);

                let temp = {
                    ...d,
                    orderApprovedTime: Common.convertUnixDateToFriendlyDate(d.order_approved_time),
                    acctDuration: acctDuration,   
                    datePushedToMfg: datePushedToMfg,
                    configDuration: invalidData ? "" : configDuration,
                    datePushedToFloor: invalidData ? "" : datePushedToFloor,
                    floorDuration: invalidData ? "" : floorDuration,
                    totalDaysInMfg: invalidData ? "" : totalDaysInMfg,
                    orderCompleteTime: d.order_complete_time ? Common.convertDateToFriendlyDate(d.order_complete_time) : "",
                    /*shipped: shipped > 0 ? "SHIPPED" : "" ,*/

                    //orderedTime: Common.convertUnixDateToFriendlyDate(d.ordered_time),
                    orderMfgStatus: statusConverter(d.orderMfgStatus)[0],
                    //statusFriendly: statusConverter(d.orderMfgStatus)[0],
                    //salesPerson: d.salesPerson,
                    ////station: "PV",
                    //expectedShipDate: d.expectedShipDate != null ? (new Date(d.expectedShipDate).toDateString()) : "",

                    ////expectedShipDate: {
                    ////	friendly: convertUnixDateToFriendlyDate(d.orderedTime + 1209600),// add two weeks (1209600) to ordered time
                    ////	unix_epoch: (d.orderedTime + 1209600) // add two weeks (1209600) to ordered time
                    ////},
                    //reasonDelayed: d.reasonDelayed,
                    //customer: getCustomer(d),
                  
                   
                };

                return temp;
            });

            //this.setState({ orders: data, loading: false });
            setProductionLog(dataOrders);
            console.info("setOrders");
        }

        // don't forget to call the function...
        order();
    }



    //
    // USE EFFECT
    //
    useEffect(() => {

        // build table columns
        setTableColumns(buildColumns());

        populateData();

        //// Update data every 1 minute
        //const interval = setInterval(() => {
        //    if (!modal) {
        //        populateOrders();
        //    }
        //}, 60000);



        // Clean up on "dismount"
        //return () => clearInterval(interval);
    },
        []
    );

    return (
        <>

            <div className="content">

                <Row >
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle>Production Log: Last 3 months</CardTitle>

                            </CardHeader>
                            <CardBody>
                                <ReactTable id="reactTable"
                                    data={productionLog}
                                    className="-striped -highlight"
                                    columns={tableColumns}
                                />
=
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>


        </>);

}

export default ProductionLog;