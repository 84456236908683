import React from "react";
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Badge,
    Spinner,
    Button,
    UncontrolledAlert,
    Modal,
    Form,
    ModalBody,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import MfgApiService from "../../MfgApiService";
import Common from "../../utils/Common.js";

const EditOrderPriority = (props) => {

    const [errorMessage, setErrorMessage] = React.useState("");
    const [editItem, setEditItem] = React.useState(props.item);


    const handleSubmit = (evt) => {
        evt.preventDefault();
        console.log('setItem', editItem);

        let obj = {
            "isNewItem": props.isNewItem,
            "item": editItem
        }

        if (obj.isNewItem) {

            console.log(editItem);

            // get a copy of the order
            MfgApiService.get('/api/orders/quoteid/' + obj.item.quoteId)
                .then(orderData => {

                    // build object
                    let newObj = {
                        "orderId": orderData.id,
                        "quoteId": orderData.quote_id,
                        "priorityLevel": obj.item.priorityLevel,
                    }
                   
                    // Add the priority item to the database
                    MfgApiService.post('/api/orderpriorities/', newObj)
                        .then(data => {
                            console.info(data);
                            props.toggle();
                            props.handleSubmit(obj);

                        })
                        .catch(error => {
                            setErrorMessage(error.toString());
                        });
                });
        }
        else {
            
            // update the priority item to the database
            MfgApiService.put('/api/orderpriorities/' + editItem.id, obj.item)
                .then(data => {
                    console.info(data);
                    props.toggle();
                    props.handleSubmit(obj);
                })
                .catch(error => {
                    setErrorMessage(error.toString());
                });
        }
    };

    React.useEffect(() => {

        console.log("editmodal", props.item);
        setEditItem(props.item);
        setErrorMessage("");

    }, [props.item]);


    return (
        <>
            {/*Setup the Edit Modal*/}
            <Modal isOpen={props.isOpen} toggle={props.toggle} fade={true} size="lg" autoFocus={false}>
                <Form onSubmit={handleSubmit}>
                    <ModalBody>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h3">Edit Order Priority</CardTitle>
                            </CardHeader>
                            <CardBody>

                                <FormGroup className="mt-3">
                                    <Label> Quote ID</Label>
                                    <Input type="text"
                                        defaultValue={editItem.quoteId}
                                        onChange={(e) => setEditItem({ ...editItem, quoteId: e.target.value })}
                                        autoFocus={true}
                                    />
                                </FormGroup>

                                <FormGroup className="mt-3">
                                    <Label> Priority Level</Label>
                                    <Input type="text"
                                        defaultValue={editItem.priorityLevel}
                                        onChange={(e) => setEditItem({ ...editItem, priorityLevel: e.target.value })}
                                    />
                                </FormGroup>

                                {/*<FormGroup className="mt-3">*/}
                                {/*    <Label> Manufacturing Notes </Label>*/}
                                {/*    <Input type="text"*/}
                                {/*        defaultValue={editItem.order.mfgNotes}*/}
                                {/*        onChange={(e) => setEditItem({ ...editItem, ...order, mfgNotes: e.target.value })}*/}
                                {/*    />*/}
                                {/*</FormGroup>*/}

                                <FormGroup>
                                </FormGroup>

                                <FormGroup check className="mt-3">
                                    <Label check>
                                        <Input type="checkbox"
                                        checked={editItem.archived != null}
                                        onChange={(e) => setEditItem({ ...editItem, archived: e.target.value  ? Common.convertDateToDateTime(Date.now()) : null})}
                                        />
                                        <span className="form-check-sign" />
								        Archive this Priority
								    </Label>
                                </FormGroup>

                            </CardBody>
                            <CardFooter>
                                
                                {errorMessage !== "" ? <div className="">
                                    
                                    <p className="text-danger">
                                        {errorMessage}
                                    </p>
                                </div> : null}

                                <Button className="btn-fill float-left" color="primary" type="submit">Submit</Button>
                                <Button className="btn-fill float-right" color="secondary" onClick={props.toggle}>Cancel</Button>
                               
                            </CardFooter>
                        </Card>
                    </ModalBody>
                </Form>
            </Modal >
        </>);
};



export default EditOrderPriority;