/*!

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

const Footer = (props) => {
  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")}>
      <Container fluid={props.fluid ? true : false}>
        <ul className="nav">
          <li className="nav-item">
            <a className="nav-link" href="/">
              PowerShades MFG API
            </a>
          </li>{" "}
          <li className="nav-item">
            <a
              className="nav-link"
              href="https://powershades.com/about"
            >
              About us
            </a>
          </li>{" "}
            <li className="nav-item">
                <a className="nav-link" target="_blank" href="https://portal.powershades.com">
            Portal
            </a>
            </li>
            <li className="nav-item">
                <a className="nav-link" target="_blank" href="https://dashboard.powershades.com">
            Dashboard
            </a>
            </li>
        <li className="nav-item">
            <a className="nav-link" target="_blank" href="/swagger">
                API Documentation
        </a>
        </li>
        </ul>
        <div className="copyright">
          © {new Date().getFullYear()} made with{" "}
          <i className="tim-icons icon-heart-2" /> by{" "}
          <a href="https://powershades.com/" target="_blank">
            PowerShades
          </a>
         
        </div>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
