import React, { Component } from 'react';
//import authService from 'components/api-authorization/AuthorizeService';


// reactstrap components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col
} from "reactstrap";

import ReactTable from "components/ReactTable/ReactTable.js";
import MfgApiService from '../MfgApiService';
import Common from "utils/Common";
import LoaderGears from "../components/LoaderGears";

export class FetchOrders extends Component {
  static displayName = FetchOrders.name;

  constructor(props) {
    super(props);
    this.state = { orders: [], loading: true };
  }

  componentDidMount() {
      this.populateOrdersData();

  }

  static renderOrdersTable(orders) {
      return (

          <div className="content">
              <Col md={8} className="ml-auto mr-auto">
                  <h2 className="text-center">MFG Orders</h2>
              </Col>
              <Row className="mt-5">
                  <Col xs={12} md={12}>
                      <Card>
                          <CardHeader>
                              <CardTitle tag="h4">Orders</CardTitle>
                          </CardHeader>
                          <CardBody>
                              <ReactTable className='table table-striped' aria-labelledby="tabelLabel"
                                  data={orders}
                                  columns={[
                                      {
                                          Header: "Ordered Time",
                                          accessor: "ordered_time",
                                          Cell: row => <div>{Common.convertUnixDateToFriendlyDate(row.value)}</div>
                                      },
                                      {
                                          Header: "Quote ID",
                                          accessor: "quote_id",
                                      },
                                      {
                                          Header: "Title",
                                          accessor: "title",
                                      },
                                      {
                                          Header: "Notes",
                                          accessor: "notes",
                                      },
                                  ]}
                              >
                              </ReactTable>
                          </CardBody>
                      </Card>
                  </Col>
              </Row>
          </div>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchOrders.renderOrdersTable(this.state.orders);

    return (
        <div className="content">
            {/* LOADING SPINNER */}
            {this.state.loading ? (
                <LoaderGears/>
            ) : null}
        {/*<h1 id="tabelLabel" >PowerShades Orders</h1>*/}
        {/*<p>This component demonstrates fetching data from the server.</p>*/}
        {contents}
      </div>
    );
  }

  async populateOrdersData() {
    //const token = await authService.getAccessToken();
    //const response = await fetch('api/orders', {
    //  headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    //});
      //const response = await fetch('api/orders');
       //const data = await response.json();

      MfgApiService.get('/api/orders')
          .then(data => {
              this.setState({ orders: data, loading: false });
          })
          .catch(error => console.error(error));
     
      
     
  }
}

export default FetchOrders;