import React from 'react';

function LoaderGears() {
    return (
        <div className="loader-gears">
            <i className="fa fa-cog fa-gear1" />
            <i className="fa fa-cog fa-gear2" />
        </div>
    );
}

export default LoaderGears;
