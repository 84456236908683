import React, { useState, useEffect } from "react";
import classnames from "classnames";
import PropTypes from 'prop-types';
import MfgApiService from 'MfgApiService';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Label,
  FormGroup
} from "reactstrap";
import { useHistory} from "react-router-dom";
import LoaderGears from "../../components/LoaderGears";

const saveToken = (data) => {
    localStorage.setItem('auth.access_token', JSON.stringify(data.access_token));
    localStorage.setItem('auth.refresh_token', JSON.stringify(data.refresh_token));
    localStorage.setItem('auth.authenticated', true);
};

const getToken = () => {
    const tokenString = localStorage.getItem('auth.access_token');
    const accessToken = JSON.parse(tokenString);
    //return accessToken?.token
    return accessToken;
};

/*export default function Login ({ setToken }) {*/
export default function Login(props) {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loginState, setLoginState] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const history = useHistory();

    const [state, setState] = React.useState({
        email: "",
        password: "",
        emailFocus: false,
        passFocus: false
    });


    const handleSubmit = async e => {
        console.log("handleSubmit");
        e.preventDefault();

        let response = await loginUser({
            username: state.email,
            password: state.password,
        });

        console.log("response", response);
       

        // See if user/role exists. If it does, then throw user object in local storage  
        MfgApiService.get('/api/mfgapiusers/user')
            .then(data => {
                console.log('User Success!', data);
                localStorage.setItem("user", JSON.stringify(data));

                //now redirect to /admin/dashboard???
                if (data != null ) {
                    history.push('/admin/mfgorderstatus');
                }

            })
            .catch(error => {
                console.error('There was an error!', error);

                //now redirect to /unauthorized???
                if (response != null) {
                    history.push('/unauthorized');
                }
            });
    }


   function loginUser(credentials) {

       setLoading(true);
        console.log("read token", getToken());

        return new Promise( (resolve, reject) => {
           // console.log(credentials);
            let access_token = null;

            MfgApiService.getToken(credentials)
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    // check for error response
                    if (!response.ok) {

                        // wipe the user data
                        localStorage.removeItem("auth.access_token");
                        localStorage.removeItem("auth.refresh_token");
                        localStorage.removeItem("auth.authenticated");


                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        //return Promise.reject(error);
                        setLoginState("has-danger");
                        reject(error);
                        setLoading(false);
                        return;
                    }

                    access_token = data.access_token;
                    // console.log('Token Data', data);
                    //setToken(data.access_token);
                    saveToken(data);

                    resolve(data.access_token);
                    //this.setState({ postId: data.id })
                })
                .catch(error => {
                    //setErrorMessage(error.toString());
                    console.error('There was an error!', error);
                    setLoading(false);
                });
            
           

            //fetch('api/auth/token', requestOptions)
            //    .then(async response => {
            //        const isJson = response.headers.get('content-type')?.includes('application/json');
            //        const data = isJson && await response.json();

            //        // check for error response
            //        if (!response.ok) {

            //            localStorage.clear(); // wipe the user data

            //            // get error message from body or default to response status
            //            const error = (data && data.message) || response.status;
            //            //return Promise.reject(error);
            //            setLoginState("has-danger");
            //            reject(error);
            //            return;
            //        }

            //        access_token = data.access_token;
            //       // console.log('Token Data', data);
            //        //setToken(data.access_token);
            //        saveToken(data);

            //        resolve(data.access_token);
            //        //this.setState({ postId: data.id })
            //    })
            //    .catch(error => {
            //        //setErrorMessage(error.toString());
            //        console.error('There was an error!', error);
            //    });
        });
    }


    React.useEffect(() => {
        document.body.classList.toggle("login-page");
        return function cleanup() {
            document.body.classList.toggle("login-page");
        };

    });

    return (
    <>
        <div className="content">

        {loading ? (
            <LoaderGears/>
        ) : null}

        <Container>
            <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form" onSubmit={handleSubmit} >
                <Card className="card-white">
                <CardHeader>
                    <img
                    alt="..."
                    src={
                        require("assets/img/Powershades_Logo_Black.png").default
                    }
                    />
                    <CardTitle
                    tag="h3"
                    color="black"
                    style={{ textAlign: "center" }}
                    >
                    Manufacturing API
                    </CardTitle>
                </CardHeader>
                <CardBody>
               
                    <InputGroup
                    className={classnames({
                        "input-group-focus": state.emailFocus
                    })}
                    >
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="tim-icons icon-email-85" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Email"
                        type="text"
                        onFocus={e => setState({ ...state, emailFocus: true })}
                        onBlur={e => setState({ ...state, emailFocus: false })}
                        onChange={e => {
                            setState({ ...state, email: e.target.value });
                            setLoginState("");
                        }}
                    />
                    </InputGroup>
                    <InputGroup
                    className={classnames({
                        "input-group-focus": state.passFocus
                    })}
                    >
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Password"
                        type="password"
                        onFocus={e => setState({ ...state, passFocus: true })}
                        onBlur={e => setState({ ...state, passFocus: false })}
                        onChange={e => {
                            setState({ ...state, password: e.target.value });
                            setLoginState("");
                        }}
                    />
                </InputGroup>
                {loginState === "has-danger" ? (
                    <Label className="error">
                        Please enter valid login credentials.
                    </Label>
                ) : null}
               
                </CardBody>
                <CardFooter>
                    <Button
                    block
                    className="mb-3"
                    color="primary"
                    //href="#pablo"
                    //onClick={e => e.preventDefault()}
                    type="submit"
                    size="lg"
                    >
                    Login
                    </Button>
                   
                    {/*<div className="pull-left">*/}
                    {/*<h6>*/}
                    {/*    <a*/}
                    {/*    className="link footer-link"*/}
                    {/*    href="#pablo"*/}
                    {/*    onClick={e => e.preventDefault()}*/}
                    {/*    >*/}
                    {/*    Create Account*/}
                    {/*    </a>*/}
                    {/*</h6>*/}
                    {/*</div>*/}
                    {/*<div className="pull-right">*/}
                    {/*<h6>*/}
                    {/*    <a*/}
                    {/*    className="link footer-link"*/}
                    {/*    href="#pablo"*/}
                    {/*    onClick={e => e.preventDefault()}*/}
                    {/*    >*/}
                    {/*    Need Help?*/}
                    {/*    </a>*/}
                    {/*</h6>*/}
                    {/*</div>*/}
                </CardFooter>
                </Card>
            </Form>
            </Col>
        </Container>
        </div>
    </>
    );
    };

// Add in the PropType from the new prop and destructure the props object to pull out the setToken prop.
//Login.propTypes = {
//    setToken: PropTypes.func.isRequired
//}