
export const Common = {

    //list functions
    convertDateToFriendlyDate,
    convertUnixDateToDateString,
    convertUnixDateToFriendlyDate,
    convertDateTimeToDate,
    convertDateToDateTime,
    dateDiffInDays
};

function convertDateTimeToDate(timestamp) {
    if (timestamp != null) {

        let d = timestamp.split('T')[0]; // Split where the T is. ex. 2021-07-10T05:00:00
        return d;
    }
    else {
        return null;
    }
}



function convertDateToFriendlyDate(timestamp) {
    if (timestamp != null) {

        //let a = timestamp.split('T')[0]; // Split where the T is. ex. 2021-07-10T05:00:00
        let a = new Date(timestamp);
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let year = a.getFullYear();
        let month = months[a.getMonth()];
        let date = a.getDate();
        //var hour = a.getHours();
        //var min = a.getMinutes();
        //var sec = a.getSeconds();
        //var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        //return time;
        let friendlyDate = date + ' ' + month + ' ' + year;
        return friendlyDate;
    }
    else {
        return null;
    }
}

function convertUnixDateToDateString(UNIX_timestamp) {
    let a = new Date(UNIX_timestamp * 1000);
    return a.toDateString();
}

function convertUnixDateToFriendlyDate(UNIX_timestamp) {
    let a = new Date(UNIX_timestamp * 1000);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();
    //var hour = a.getHours();
    //var min = a.getMinutes();
    //var sec = a.getSeconds();
    //var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    //return time;
    let friendlyDate = date + ' ' + month + ' ' + year;
    return friendlyDate;
}


function convertDateToDateTime(UNIX_timestamp) {
    
    var date = new Date(UNIX_timestamp);
    var day = date.getDate();       // yields date
    var month = date.getMonth() + 1;    // yields month (add one as '.getMonth()' is zero indexed)
    var year = date.getFullYear();  // yields year
    var hour = date.getHours();     // yields hours 
    var minute = date.getMinutes(); // yields minutes
    var second = date.getSeconds(); // yields seconds

    // After this construct a string with the above results as below
    var time = year + "-" + month + "-" + day + " " + hour + ':' + minute + ':' + second;
    return time;
}


const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
function dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}





export default Common;






// return the user data from the session storage
export const getUserSession = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
}

// return the token from the session storage
export const getTokenSession = () => {
    return sessionStorage.getItem('token') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
}