/*eslint-disable*/
import React from "react";
import {
    useTable,
    useFilters,
    useAsyncDebounce,
    useSortBy,
    usePagination,
    useGlobalFilter,
    useFlexLayout
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// reactstrap components
import { Form, FormGroup, Input, Row, Col, Label, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import { useState, useEffect } from "react";
//import { useState, forwardRef, useImperativeHandle } from 'react';





// Define a default UI for filtering
function GlobalFilter(
    {
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const [searchFocus, setSearchFocus] = React.useState("")

    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <Row>
            <Col md="4" />
            <Col md="4">
                <InputGroup className={searchFocus}>
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="tim-icons icon-zoom-split" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        value={value || ""}
                        onChange={(e) => {
                            setValue(e.target.value);
                            onChange(e.target.value);
                        }}
                       
                        placeholder={`Global Search ${count} records...`}
                        onFocus={(e) =>setSearchFocus("input-group-focus") }
                        onBlur={(e) => setSearchFocus("") }
                    />
                </InputGroup>
            </Col>
            <Col md="4" />
        </Row>

    )
}


// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length;

    return (
        <FormGroup>
            <Input
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${count} records...`}
            />
        </FormGroup>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Create a default prop getter
const defaultPropGetter = () => ({})

// Our table component
function Table({
    columns,
    data,
    getRowProps = defaultPropGetter })
{
    const [numberOfRows, setNumberOfRows] = React.useState(10);
    const [pageSelect, handlePageSelect] = React.useState(0);
    const [timerStatus, setTimerStatus] = React.useState(false);
    const [myObj] = React.useState({});


    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,

            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                console.log(rows, id, filterValue);
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },

            //quote id field
            orderIdFilter: (rows, id, filterValue) => {
                console.log(rows, id, filterValue);
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        visibleColumns,
        nextPage,
        pageOptions,
        pageCount,
        previousPage,
        canPreviousPage,
        canNextPage,
        setPageSize,
        gotoPage,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
            initialState: {
                pageSize: 20, pageIndex: 0,
                /*hiddenColumns: ['quote_id'] // hide this column as it is currently only being used for global search*/
            },
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination,
        useFlexLayout
    );
      
    // We don't want to render all of the rows for this example, so cap
    // it for this use case
    // const firstPageRows = rows.slice(0, 10);
    let pageSelectData = Array.apply(
        null,
        Array(pageOptions.length)
    ).map(function () { });
    let numberOfRowsData = [5, 10, 20, 25, 50, 100, 1000];

    // Handle UseEffect
    useEffect(() => {
        const interval = setInterval(() => {autoNextPage();}, 5000);
        return () => clearInterval(interval);
    }, []);

    myObj.count = myObj.count ?? 0;
    myObj.autoState = timerStatus;
    myObj.canNextPage = canNextPage;

    return (
        <>
            <div className="ReactTable -striped -highlight">
              
                <GlobalFilter
                    preGlobalFilteredRows={data}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />

                <div className="pagination-top">                  
                </div>

                {/*Build the table*/}
                <table {...getTableProps()} className="rt-table">
                    <thead className="rt-thead -header">
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
                                {headerGroup.headers.map((column, key) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        className={classnames("rt-th rt-resizable-header", {
                                            "-cursor-pointer": headerGroup.headers.length - 1 !== key,
                                            "-sort-asc": column.isSorted && !column.isSortedDesc,
                                            "-sort-desc": column.isSorted && column.isSortedDesc,
                                        })}
                                    >
                                        <div className="rt-resizable-header-content">
                                            {column.render("Header")}
                                        </div>
                                         {/*Render the columns filter UI */}
                                        <div>
                                            {headerGroup.headers.length - 1 === key
                                                ? null
                                                : column.canFilter
                                                    ? column.render("Filter")
                                                    : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="rt-tbody">
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                // Merge user row props in with {...getRowProps(row)} 
                                <tr
                                    {...row.getRowProps()}
                                    style={{ textAlign: "center" }}
                                    className={classnames(
                                        "rt-tr",
                                        { " -odd": i % 2 === 0 },
                                        { " -even": i % 2 === 1 }
                                    )}
                                    {...getRowProps(row)} 
                                >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className="rt-td">
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="pagination-bottom">
                    <div className="-pagination">
                        <div className="-previous">
                            <button
                                type="button"
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                                className="-btn"
                            >
                                Previous
                            </button>
                        </div>
                        <div className="-center flex-nowrap">
                            <Select
                                className="react-select info mx-5 w-100"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={pageSelect}
                                onChange={(value) => {
                                    gotoPage(value.value);
                                    handlePageSelect(value);
                                }}
                                options={pageSelectData.map((prop, key) => {
                                    return {
                                        value: key,
                                        label: "Page " + (key + 1),
                                    };
                                })}
                                placeholder="Select page"
                            />
                            <button
                                type="button"
                                onClick={() => {
                                    setTimerStatus(!timerStatus);
                                }}
                                className="-btn"
                            >
                                Auto {timerStatus ? "ON" : "OFF"}
                            </button>
                            <Select
                                className="react-select info mx-5 w-100"
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={numberOfRows}
                                onChange={(value) => {
                                    console.log(value);
                                    setPageSize(value.value);
                                    setNumberOfRows(value);
                                }}
                                options={numberOfRowsData.map((prop) => {
                                    return {
                                        value: prop,
                                        label: prop + " rows",
                                    };
                                })}
                                placeholder="Select #rows"
                            />
                        </div>
                        <div className="-next">
                            <button
                                type="button"
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                                className="-btn"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

    // automatically go to the next page
    function autoNextPage() {

        if (myObj.autoState) {

            if (myObj.canNextPage)
                myObj.count = myObj.count + 1;
            else
                myObj.count = 0;

            gotoPage(myObj.count);
            console.info(myObj.count);
        }
    }
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}





// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
