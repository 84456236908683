import React, { useEffect, useState } from "react";
import ReactTable from "components/ReactTable/ReactTable";
import MfgApiService from "../MfgApiService";
import classNames from "classnames";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    Form,
    InputGroup,
    CardFooter,
    FormGroup,
    Label,
    Input,
    InputGroupText,
    InputGroupAddon,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown,
    
} from "reactstrap";



const MfgApiUsers = () => {

    /* STATE */
    const [apiUsers, setApiUsers] = useState([]); /* ARRAY of user objects */
    const [loading, setLoading] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
    const [email, setEmail] = useState("");
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);
    const [selectedRole, setSelectedRole] = useState('Select Role')


    const [alert, setAlert] = React.useState(null);
    const hideAlert = () => {
        setAlert(null);
    };
    const successAlert = (message) => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Good job!"
                onConfirm={() => hideAlert()}
              
                confirmBtnBsStyle="success"
                btnSize=""
            >
                {message}
      </ReactBSAlert>
        );
    };


    /* FUNCTIONS*/
    const populateUsers = async () => {

        let deezUsers = async () => {
            await MfgApiService.get('/api/mfgapiusers')
                .then(data => {
                    console.log('Success!', data);
                    if (data != null) {
                        setApiUsers(data);
                        setLoading(false);
                    }
                })
                .catch(error => console.error("error", error));
        }

        await deezUsers();
    };

    const handleFindUser = async () => {
        let authUser = {};
        let url = "/api/auth/authuserbyemail?email=" + email;
       
        MfgApiService.get(url)
            .then(data => {
                authUser = data.data;
                console.log("authuser", authUser);

                // now add the user to our database
                let body = {
                    name: authUser.name,
                    email: authUser.email,
                    authServerUserId: authUser.id,
                    mfgApiRoleId: selectedRole.value,
                    //created: Date.now(),
                    //updated: Date.now(),
                    //clocknumber: "",
                    //archived: 0
                };

                console.log('body', body);
                MfgApiService.post("/api/mfgapiusers", body)
                    .then(data => {
                        if (data != null) {
                            successAlert("You added user " + data.name);
                            populateUsers();
                        }
                        else {
                            console.error("error adding user");
                        }
                    })
                    .catch(error => console.error('catch', error));
            })
            .catch(error => console.error(error));
    
        
    }

    /* USE EFFECT*/
    useEffect(() => {

        populateUsers();
        console.log("useEffect");
    }, []);

    /* RETURN COMPONENT */
    return (
        <>
            <div className="content">
                {alert}
                <Col md={8} className="ml-auto mr-auto">
                    <h2 className="text-center">MFG API Users</h2>
                </Col>
                <Row className="mt-5">
                    <Col xs={6} md={6}>


                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Add User</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form className="form">
                                    <InputGroup
                                        className={classNames({
                                            "input-group-focus": emailFocus,
                                        })}
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="tim-icons icon-email-85" />
                                            </InputGroupText>
                                        </InputGroupAddon>

                                        <Input
                                            placeholder="Email"
                                            type="text"
                                            onFocus={(e) => setEmailFocus(true)}
                                            onBlur={(e) => setEmailFocus(false)}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </InputGroup>
                                    <Select
                                        className="react-select info"
                                        classNamePrefix="react-select"
                                        name="selectRole"
                                        value={selectedRole}
                                        onChange={(value) => setSelectedRole(value)}
                                        options={[
                                            { value: "3", label: "admin" },
                                            { value: "4", label: "user" },
                                            { value: "5", label: "viewer" },
                                        ]}
                                        placeholder="Select Role"
                                    />
                                  
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <p>User must already exist in Auth Server (Dashboard)</p>
                                <Button
                                    className="btn-round"
                                    color="primary"
                                    href="#pablo"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleFindUser();
                                    }}
                                    size="lg"
                                >
                                    Find User
                                </Button>
                            </CardFooter>
                        </Card>



                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Users Table</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <ReactTable className='table table-striped' aria-labelledby="tabelLabel"
                                    data={apiUsers}
                                    columns={[
                                        {
                                            Header: "Auth User Id",
                                            accessor: "authServerUserId",
                                        },
                                        {
                                            Header: "Name",
                                            accessor: "name",
                                        },
                                        {
                                            Header: "Email",
                                            accessor: "email",
                                        },
                                        {
                                            Header: "Mfg Api Role Id",
                                            accessor: "mfgApiRoleId",
                                        },
                                        {
                                            Header: "Role Name",
                                            accessor: "mfgApiRole.roleName",
                                        },
                                        {
                                            Header: "Clock Number",
                                            accessor: "clockNumber",
                                        },
                                    ]}
                                >
                                </ReactTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>);
};

export default MfgApiUsers;