// MfgApi.js
//
//functions to make api calls

const buildHeaders = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
};

const buildHeadersWithToken = () => {
    const tokenString = localStorage.getItem("auth.access_token");
    const accessToken = JSON.parse(tokenString);
    return JSON.stringify({
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    });
}


export const MfgApiService = {

    //list functions
    getToken,
    refreshToken,
    get,
    put,
    post

};

function getToken(credentials) {

    
    let body = JSON.stringify(credentials);
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: body
    };
    console.log(process.env.REACT_APP_API_BASE_URL);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    return fetch(apiBaseUrl + '/api/auth/token', requestOptions);
}

/**
 * @param {string} refresh_token - The token as a string
 */
function refreshToken(refresh_token) {

    // make this an object to follow convention of serialization / deserialization of DTOs
    let body = JSON.stringify({ refresh_token: refresh_token });

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: body
    };
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    return fetch(apiBaseUrl + '/api/auth/refresh', requestOptions);
}

function get(url) {

    const tokenString = localStorage.getItem("auth.access_token");
    const accessToken = JSON.parse(tokenString);

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        }
    };
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    console.log("get", apiBaseUrl + url, requestOptions);
    return fetch(apiBaseUrl + url, requestOptions).then(handleResponse);
}

function put(url, body) {

    const tokenString = localStorage.getItem("auth.access_token");
    const accessToken = JSON.parse(tokenString);

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(body)
    };

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    return fetch(apiBaseUrl + url, requestOptions).then(handleResponse);
}


function post(url, body) {

    const tokenString = localStorage.getItem("auth.access_token");
    const accessToken = JSON.parse(tokenString);

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(body)
    };
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    return fetch(apiBaseUrl + url, requestOptions).then(handleResponse);
}



//
// Helpers
//
function handleResponse(response) {
    console.log("handleResponse");
    return response.text().then(text => {
        console.log("response.text()", text);

        try {
            const data = text && JSON.parse(text);
       
            if (!response.ok) {
                console.error(data);
                const error = (data && data.message) || response.statusText;
                console.error(error);
                return Promise.reject(error);
            }

            return data;
        }
        catch (e) {
            console.error(e);
        } 
    });
}



export default MfgApiService;


// example usage
//fetchWrapper.post('https://jsonplaceholder.typicode.com/posts', { title: 'Fetch Wrapper POST Request Example' })
//    .then(data => console.log('Success!', data))
//    .catch(error => console.error('There was an error!', error));