import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Note: this doesn't work for the routes that use Layouts like Admin Layout
const ProtectedRoute = ({ component: Component, user, ...restOfProps }) => {
    return (
        <Route {...restOfProps} render={
            props => {
                let authenticated = localStorage.getItem('authenticated');
                if (authenticated){
                //if (user) {
                    return <Component {...restOfProps} {...props} />
                } else {
                    return <Redirect to={
                        {
                            //pathname: '/auth/login',
                            pathname: '/unauthorized',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }
        } />
    )
}

export default ProtectedRoute;


//import React from "react";
//import { Redirect, Route } from "react-router-dom";

//function ProtectedRoute({ component: Component, ...restOfProps }) {
//    const isAuthenticated = localStorage.getItem("auth.authenticated");
//    console.log("authenticated", isAuthenticated);

//    return (
//        <Route
//            {...restOfProps}
//            render={(props) =>
//                isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
//            }
//        />
//    );
//}

//export default ProtectedRoute;