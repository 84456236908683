


import React, { useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import LoaderGears from "../components/LoaderGears";

// reactstrap components
import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Progress,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Spinner
} from "reactstrap";

// core components
import {
    chartExample1,
    chartExample2,
    chartExample3,
    chartExample4,
} from "variables/charts.js";

import {
    chartOrdersShipped
} from "variables/ordersShipped.js";
import MfgApiService from "MfgApiService";


const buildBigChartData = (label, data) => {
    // return the data whole data object for the chart to consume
    return {
        labels: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
        datasets: [
            {
                label: label,
                data: data,
                fill: true,
                //backgroundColor: gradientStroke,
                borderColor: "#1f8ef1",
                borderWidth: 2,
                borderDash: [],
                borderDashOffset: 0.0,
                pointBackgroundColor: "#1f8ef1",
                pointBorderColor: "rgba(255,255,255,0)",
                pointHoverBackgroundColor: "#1f8ef1",
                pointBorderWidth: 20,
                pointHoverRadius: 4,
                pointHoverBorderWidth: 15,
                pointRadius: 4,
            },
        ]
    }
}

const Dashboard = () => {


    const [loading, setLoading] = React.useState(true);
    const [totalShipments, setTotalShipments] = React.useState(0);
    const [totalOrders, setTotalOrders] = React.useState(0);
    const [totalShades, setTotalShades] = React.useState(0);

    const shipmentsChartData = React.useRef({});
    const ordersChartData = React.useRef({});
    const shadesChartData = React.useRef({});

    const [chartDataShipmentsSummary, setChartDataShipmentsSummary] = React.useState({});
    const [chartDataOrdersSummary, setChartDataOrderSummary] = React.useState({});
    const [chartDataShadesSummary, setChartDataShadesSummary] = React.useState({});
    const [bigChartData, setBigChartData] = React.useState({});
    const [activeBigChart, setActiveBigChart] = React.useState("");
    const [user, setUser] = React.useState({});
   

    const setTheBigChartData = async (name) => {

        return new Promise( (resolve, error) => {

            switch (name) {
                case "Orders":
                    setBigChartData({ ...ordersChartData.current });
                    console.log({ ...ordersChartData });
                    break;
                case "Shades":
                    setBigChartData({ ...shadesChartData.current});
                    break;
                case "Shipments":
                    setBigChartData({ ...shipmentsChartData.current });
                    break;
                default:
                    setBigChartData({});
                    break;
            }

            setActiveBigChart(name); //tells which button to set as active
            console.log("setTheBigChartData", name)
            
            resolve();
        });
    };

    async function ChartTotalShipments() {

        let shipments = async () => {

            //
            // get the shipment data
            //
            await MfgApiService.get('/api/shipments/summary').then(
                newData => {

                    // Format the data into an array
                    let dataArray = [];
                    let tempTotalShipments = 0;
                    let dataTotalShipments = newData.totalshipments;
                    // console.info("totalshipments " + dataTotalShipments);

                    for (let i = 0; i < 12; i++) {
                        let dataObj = newData.data.find(obj => {
                            return (obj.month === i + 1 && obj.year === 2021)
                        });

                        if (dataObj) {
                            dataArray[i] = dataObj.value;
                            tempTotalShipments += dataObj.value;
                        }
                        else {
                            dataArray[i] = 0;
                        }
                    }
                    //console.info(dataArray);

                    // now we need to build the chart data object
                    shipmentsChartData.current = buildBigChartData("Shipments", dataArray);
                    setChartDataShipmentsSummary(shipmentsChartData.current);
                    setTotalShipments(tempTotalShipments);
                    //console.info(tempTotalShipments);
                    console.log("shipments");
                }
            );
        };

        await shipments();
        }


    async function ChartTotalOrders() {
        let orders = async () => {
            // get the order summary data
            await MfgApiService.get('/api/orders/summary').then(
                newData => {

                    // Format the data into an array
                    let dataArray = [];
                    let tempTotalOrders = 0;
                    let dataTotalOrders = newData.totalOrders;
                    //console.info("totalOrders " + dataTotalOrders);

                    for (let i = 0; i < 12; i++) {
                        let dataObj = newData.data.find(obj => {
                            return (obj.month === i + 1 && obj.year === 2021)
                        });

                        if (dataObj) {
                            dataArray[i] = dataObj.value;
                            tempTotalOrders += dataObj.value;
                        }
                        else {
                            dataArray[i] = 0;
                        }
                    }
                    //console.info(dataArray);
                    ordersChartData.current = buildBigChartData("Orders", dataArray);
                    setChartDataOrderSummary(ordersChartData.current);
                    setTotalOrders(tempTotalOrders);

                    console.log("orders");
                }
            );
        };

        await orders();
    }
    async function ChartTotalShades() {
        let shades = async () => {
            // get the Shade summary data
            await MfgApiService.get('/api/shades/summary').then(
                newData => {

                    // if (!response.ok) {
                    //     throw new Error(`HTTP error! status: ${response.status}`);
                    // }

                    // const newData = await response.json();
                    // console.info(newData);

                    // Format the data into an array
                    let dataArray = [];
                    let tempTotalShades = 0;
                    let dataTotalShades = newData.totalShades;
                    //console.info("totalShades " + dataTotalShades);

                    for (let i = 0; i < 12; i++) {
                        let dataObj = newData.data.find(obj => {
                            return (obj.month === i + 1 && obj.year === 2021)
                        });

                        if (dataObj) {
                            dataArray[i] = dataObj.value;
                            tempTotalShades += dataObj.value;
                        }
                        else {
                            dataArray[i] = 0;
                        }
                    }
                    //console.info(dataArray);
                    shadesChartData.current = buildBigChartData("Shades", dataArray);
                    setChartDataShadesSummary(shadesChartData.current);
                    setTotalShades(tempTotalShades);
                    console.log("shades");
                });
        };

        await shades();
    }

    // FETCH DATA VIA USE EFFECT
    React.useEffect(async () => {

        setUser(JSON.parse(localStorage.getItem("user")));
        
        await ChartTotalShipments();
        await ChartTotalOrders();
        await ChartTotalShades();

        await setTheBigChartData("Shipments");
        setLoading(false);
        console.log("useEffect");

    }, []
    );



    // RETURN THE COMPONENT
    return (
        <>

            <div className="content">       

                {/*Setup the Loading component*/}
                {loading ? (
                   <LoaderGears/>
                ) : null}
               
                <Row>
                    <Col xs="12">
                        <Card className="card-chart">
                            <CardHeader>
                                <Row>
                                    <Col className="text-left" sm="6">
                                        <h5 className="card-category">Per Month</h5>
                                        <CardTitle tag="h2">Performance</CardTitle>
                                    </Col>
                                    <Col sm="6">
                                        <ButtonGroup
                                            className="btn-group-toggle float-right"
                                            data-toggle="buttons"
                                        >

                                            <Button
                                                color="info"
                                                id="btnShipments"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: activeBigChart === "Shipments",
                                                })}
                                                onClick={() => setTheBigChartData("Shipments")}
                                            >
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Shipments
                        </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-single-02" />
                                                </span>
                                            </Button>


                                            <Button
                                                color="info"
                                                id="1"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: activeBigChart === "Orders",
                                                })}
                                                onClick={() => setTheBigChartData("Orders")}
                                            >
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Orders Shipped
                        </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-gift-2" />
                                                </span>
                                            </Button>


                                            <Button
                                                color="info"
                                                id="2"
                                                size="sm"
                                                tag="label"
                                                className={classNames("btn-simple", {
                                                    active: activeBigChart === "Shades",
                                                })}
                                                onClick={() => setTheBigChartData("Shades")}
                                            >
                                                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                    Shades
                        </span>
                                                <span className="d-block d-sm-none">
                                                    <i className="tim-icons icon-tap-02" />
                                                </span>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <div className="chart-area">
                                    <Bar
                                        data={bigChartData}
                                        options={chartExample3.options}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                   

                    <Col lg="4">
                        <Card className="card-chart">
                            <CardHeader>
                                <h5 className="card-category">Total Shipments</h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-delivery-fast text-primary" /> {totalShipments}
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="chart-area">
                                    <Line
                                        id="shipmentsChart"
                                        data={chartDataShipmentsSummary}
                                        options={chartOrdersShipped.options}
                                        label="Shades"
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>



                    <Col lg="4">
                        <Card className="card-chart">
                            <CardHeader>
                                <h5 className="card-category">Total Orders</h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-bell-55 text-info" />{" "}
                                    {totalOrders}
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="chart-area">
                                    <Line
                                        id="OrdersChart"
                                        data={chartDataOrdersSummary}
                                        options={chartOrdersShipped.options}
                                        label="Orders Shipped"
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>


                    <Col lg="4">
                        <Card className="card-chart">
                            <CardHeader>
                                <h5 className="card-category">Manufactured Shades</h5>
                                <CardTitle tag="h3">
                                    <i className="tim-icons icon-send text-success" /> {totalShades}
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <div className="chart-area">
                                    <Line
                                        id="ShadesChart"
                                        data={chartDataShadesSummary}
                                        options={chartOrdersShipped.options}
                                        label="Shades"
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );
};

export default Dashboard;





/*!   TEMPLATE INFO

=========================================================
* Black Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/