
// nodejs library that concatenates classes
import classNames from "classnames";
//import authService from 'components/api-authorization/AuthorizeService';
import ReactTableMfgOrderStatus from "components/ReactTable/ReactTableMfgOrderStatus.js";
import React, { useEffect, useState } from "react";

// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";
import Common from "../utils/Common.js";

// reactstrap components
import {
    Button, Card,
    CardBody,
    CardFooter, CardHeader,
    CardTitle,
    Col,
    Form, FormGroup,
    Input,
    Label, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Progress,
    Row,
    Table
} from "reactstrap";

// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

import MfgApiService from "../MfgApiService";
import LoaderGears from "../components/LoaderGears.js";


const getTypeColor = (type) => {

    if (type)
        return dictOrderTypeColors[type.toUpperCase()];
    else
        return "Black";
}

const dictOrderTypeColors = {
    "CEDIA": "Black",
    "RMA": "Orange",
    "REWORK": "Red",
    "BIDSPEC": "Green",
    "INTERNAL": "LightSeaGreen",
    "": "Black"
}


const ORDER_STATUS_BITS = {

    ORDER_ACTIVE: 0x0001,
    WOPR_PRINTED: 0x0002,
    SAW_CUT_COMPLETE: 0x0004,
    FABRIC_CUT_COMPLETE: 0x0008,
    ASSEMBLY_COMPLETE: 0x0010,
    PROVISION_COMPLETE: 0x0020,
    PUSHED_TO_DASHBOARD: 0x0040,
    SLEEVE_COMPLETE: 0x0080,
    SHADES_BOXED: 0x0100,
    ACCESSORIES_BOXED: 0x0200,
    SHIPPED: 0x0400,
    HARDWARE_BOXED: 0X0800,
    FASCIA_BOXED: 0x1000,
    ORDER_ABORTED: 0x040000000,
};


function getCustomer(data) {

    if (data['dealer'] !== "") {
        return data['dealer'];
    }
    else if (data['distributor'] !== "") {
        return data['distributor'];
    }
    else if (data['representative'] !== "") {
        return data['representative'];
    }
    else
        return "error";
}

function statusConverter(status) {

    if (status & ORDER_STATUS_BITS.ORDER_ABORTED)
        return ["Aborted", "100", "progress-aborted"];

    if (status & ORDER_STATUS_BITS.SHIPPED)
        return ["Shipped", "100", "progress-shipped"];

    if (status & ORDER_STATUS_BITS.PROVISION_COMPLETE)
        return ["Provisioned", "50", "progress-primary"];

    if (status & ORDER_STATUS_BITS.SHADES_BOXED)
        return ["Shades Boxed", "75", "progress-primary"];

    return ["Pending", "25", "progress-info"];
}

const MfgOrderStatus = () => {

    let mfgApiUsers = [];
    let portalShipments = [];
    const [hideCompleted, setHideCompleted] = React.useState(false);
    const [orders, setOrders] = useState([]);
    const [submittedQuotes, setSubmittedQuotes] = useState([]);
    const [loading, setLoading] = useState(false);
    //const [user, setUser] = useState({});
    const [tableColumns, setTableColumns] = useState([]);

    // editing the order
    const [modal, setModal] = useState(false);
    const toggleEditModal = () => setModal(!modal);
    const [editOrder, setEditOrder] = useState({});
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
    

    const setEditOrderObj = (obj) => {
        setEditOrder({ ...editOrder, ...obj });
        //console.info("setEditOrderObj", editOrder);
    };
    const handleSubmit = (evt) => {
        evt.preventDefault();
        //setEditOrderObj({ reasonDelayed: editOrderReasonDelayed });
        updateOrder(); //update database
        //populateOrders();
    };

    const updateDate = (newDate) => {
        setEditOrderObj({ expectedShipDate: newDate.toDate() });
    }


    async function updateOrder() {

        // Update the database with new info.
        MfgApiService.put('/api/orders/' + editOrder.id, editOrder)
            .then(data => {
                console.info(data);
                populateOrders(hideCompleted);
            })
            .catch(error => console.error(error));
    }

    const populateOrders = (hideCompletedOrders) => {

        setLoading(true);

        let order = async () => {

            const tokenString = localStorage.getItem("auth.access_token");
            const accessToken = JSON.parse(tokenString);

           
            //Get the users list
            //await MfgApiService.get("/api/mfgapiusers").then(
            //    newData => {
            //        mfgApiUsers = newData;
            //        //console.log("users", mfgApiUsers);
            //    }
            //);

            //Get the shipment data from portal list
            await MfgApiService.get("/api/portalapi/shipments").then(
                newData => {
                    portalShipments = newData;
                   // console.log("portalShipments", portalShipments);
                }
            );

            // Get the Submitted to MFG orders 
            let submittedQuotesResponse = [];
            await MfgApiService.get("/api/portalapi/submittedtomfg").then(
                newData => {
                    submittedQuotesResponse = newData;
                   // console.log("users", submittedQuotesResponse);
                }
            );

            // Get the Submitted to MFG orders 
            let data = [];
            await MfgApiService.get('/api/orders/mfgstatus').then(
                newData => {
                    data = newData;
                   // console.log("data", data);
                }
            );

            // Default: Hide the completed orders. If Show Completed Orders is checked, let's show all the orders
            if (hideCompletedOrders) {
                const incompleteOrders = data.filter(o => o.order_complete_time === null);
                data = incompleteOrders;
            }
            //console.log("data count: ", data.length);

            // see if we can add the submitted to mfg to data array
            submittedQuotesResponse.return_quotes.map(q => {

                // make sure we don't already have an order in mfg orders
                if (!data.find(x => x.quote_id === q.quote.id)) {
                    //console.log(q);

                    // shove all these properties inside the quote so it's easier on the next step.
                    q.quote['quote_id'] = q.quote.id;
                    q.quote["orderType"] = "submitted";
                    q.quote['submitted_to_mfg'] = true;
                    q.quote['woprStation'] = "SUB2MFG";
                    //let sp = mfgApiUsers.find(x => x.authServerUserId === q.quote.salesperson_id);
                    //console.log("sp", sp);
                    q.quote['salesPerson'] = q.salesperson_name;
                    
                    data.push(q.quote);
                }
            });
           
            //console.log("data concat count: ", data.length);
           
            //change the unix timestamps to human readable. And do other things too.
            let dataOrders = data.map(d => {
                let temp = {
                    ...d,
                   
                    submittedToMfg: d.submitted_to_mfg ? d.submitted_to_mfg : false, // add this so we can see the column
                    orderCompleteTime: Common.convertDateToFriendlyDate(d.order_complete_time),
                    orderApprovedTime: Common.convertUnixDateToFriendlyDate(d.order_approved_time),
                    orderedTime: Common.convertUnixDateToFriendlyDate(d.ordered_time),
                    orderMfgStatus: statusConverter(d.orderMfgStatus)[0],
                    statusFriendly: statusConverter(d.orderMfgStatus)[0],
                    salesPerson: d.salesPerson,
                    //station: "PV",
                    expectedShipDate: d.expectedShipDate != null ? Common.convertDateToFriendlyDate(d.expectedShipDate) : "",

                    //expectedShipDate: {
                    //	friendly: convertUnixDateToFriendlyDate(d.orderedTime + 1209600),// add two weeks (1209600) to ordered time
                    //	unix_epoch: (d.orderedTime + 1209600) // add two weeks (1209600) to ordered time
                    //},
                    reasonDelayed: d.reasonDelayed,
                    customer: getCustomer(d),
                    //quoteIds: (
                    //    <div className="bordered-div-quote-id">{d.quote_id}</div>
                    //),
                    quoteIdButton: (
                        <div className="actions-left" id={d.quote_id}>
                            <Button
                                onClick={() => {
                                    let obj = data.find((o) => o.quote_id === d.quote_id);
                                    alert(
                                        "You've clicked LIKE button on \n{ \nQuote: " +
                                        obj.quoteId +
                                        ", \nTitle: " +
                                        obj.title +
                                        ", \nDealer: " +
                                        obj.dealer +
                                        ", \nOrdered Time: " +
                                        obj.orderedTime +
                                        ", \nStatus: " +
                                        obj.statusFriendly +
                                        ", \nId: " +
                                        obj.id +
                                        "\n}."
                                    );
                                }}
                                className="btn-simple btn-primary btn-simple-block"
                            >
                                {d.quote_id}
                            </Button>
                        </div>
                    ),
                    status: (
                        <div className={classNames("progress-container " + statusConverter(d.orderMfgStatus)[2])}>
                            <span className="progress-badge">{statusConverter(d.orderMfgStatus)[0]}</span>
                            <Progress max="100" value={statusConverter(d.orderMfgStatus)[1]}>
                                <span className="progress-value">{statusConverter(d.orderMfgStatus)[1]}%</span>
                            </Progress>
                        </div>
                    ),

                    shipmentsList: (
                        <div>
                            {portalShipments.quote_shipments.filter(ps => { return ps.quote_id === d.quote_id })
                                .map(s => {
                                    //console.log("sh", s);
                                    return (
                                        <div>
                                            <a className="shipping-link"
                                                href={'https://google.com/search?q=' + s.tracking_number}
                                                target="_blank">{s.carrier + " " + s.tracking_number}
                                            </a>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    ),

                    // only admins get to see actions
              
                    actions:  (

                        // Add some custom button actions
                        <div className="actions-right">
                            <Button 
                                onClick={() => {

                                    let obj = data.find((o) => o.quote_id === d.quote_id);

                                    // Get the shipments on the order 
                                    let shipments = [];
                                    MfgApiService.get(`/api/portalapi/shipments/${d.quote_id}`).then(
                                        newData => {
                                            shipments = newData;
                                            if (shipments) {
                                                console.log("shipments", shipments);
                                                obj.shipments = shipments.quote_shipments;
                                                setEditOrder(obj);
                                            }
                                        }
                                    ).catch(
                                        error => {
                                            console.error(error)
                                            setEditOrder(obj)
                                        }
                                    );

                                    //setEditOrder(obj);
                                    toggleEditModal();
                                }}
                                color="info"
                                size="md"
                                className={classNames(
                                    "btn-icon btn-link like",
                                    { "btn-neutral": !(d.orderMfgStatus & ORDER_STATUS_BITS.SHIPPED),},
                                )}
                            >
                            <i className="tim-icons icon-pencil" />
                            </Button>
                            {" "}
                        </div >
                    ),
                };
                setLoading(false);
                return temp;
            });

            //this.setState({ orders: data, loading: false });
            setOrders(dataOrders);
            //console.info("setOrders");
        }


        // don't forget to call the function...
        order();

       


    }


    const friendlyStationName = (station) => {
        //console.log(station);
        if (!station)
            return "";

        if (station.includes("SUB2MFG"))
            return "SUB2MFG";

        else if (station.includes("MF"))
            return "PROCESSING";

        else if (station.includes("CC_"))
            return "CFG CTL";

        else if (station.includes("IR"))
            return "INVENTORY";

        else if (station.includes("KT"))
            return "KITTING";

        else if (station.includes("SC"))
            return "SAW";

        else if (station.includes("FC"))
            return "FABRIC";

        else if (station.includes("WN"))
            return "WINDING";

        else if (station.includes("WN"))
            return "WINDING";

        else if (station.includes("HO"))
            return "HOIST";

        else if (station.includes("WS"))
            return "WELDING";

        else if (station.includes("AS"))
            return "ASSEMBLY";

        else if (station.includes("PV"))
            return "PROVISION";

        else if (station.includes("SL"))
            return "SLEEVE";

        else if (station.includes("BS"))
            return "BANDING";

        else if (station.includes("BX"))
            return "BOXING";

        else if (station.includes("SS"))
            return "SHIPPING";

        else if (station.includes("WH"))
            return "WAREHOUSE";

        else if (station.includes("FI"))
            return "FILE";
        else
            return station;

    }


    const sortByDateComplete = (a, b) => {
        //console.log("sort", a, b);

        if (a.original["order_complete_time"] === undefined) {
            return -1;
        }
        if (b.original === undefined) {
            return 1;
        }

        var a1 = new Date(a.original["order_complete_time"]);
        var b1 = new Date(b.original["order_complete_time"]);
        if (a1 > b1)
            return 1;
        else if (a1 < b1)
            return -1;
        else
            return 0;
    };

    const sortByDateApprovedTime = (a, b) => {
        //console.log("sort", a, b);

        if (a.original["order_approved_time"] === undefined) {
            return -1;
        }
        if (b.original === undefined) {
            return 1;
        }

        var a1 = new Date(a.original["order_approved_time"]);
        var b1 = new Date(b.original["order_approved_time"]);
        if (a1 > b1)
            return 1;
        else if (a1 < b1)
            return -1;
        else
            return 0;
    };

    const sortByDateOrderedTime = (a, b) => {
        //console.log("sort", a, b);

        if (a.original["ordered_time"] === undefined) {
            return -1;
        }
        if (b.original === undefined) {
            return 1;
        }

        var a1 = new Date(a.original["ordered_time"]);
        var b1 = new Date(b.original["ordered_time"]);
        if (a1 > b1)
            return 1;
        else if (a1 < b1)
            return -1;
        else
            return 0;
    };

    const buildColumns = (hideCompletedColumn) => {
        let myColumns =
            [
                //{
                //    Header: "Submitted",
                //    accessor: "submittedToMfg",
                //},
                //{
                //    //Header: () => (<div style={{ textAlign: "center" }}>Status</div>),
                //    Header: "Status",
                //    Cell: row => <div style={{ textAlign: "left" }}>{row.value}</div>,
                //    accessor: "status"
                //},
                {
                    Header: () => (<div style={{ textAlign: "center" }}>Type</div>),
                    Cell: cell => <div style={{
                        textAlign: "center",
                        color: getTypeColor(cell.value)
                    }}>{cell.value ? cell.value.toUpperCase() : cell.value}</div>,
                    accessor: "orderType",
                    width: 120,
                    Filter: SelectColumnFilter,
                },
                //{
                //    Header: () => (<div style={{ textAlign: "center" }}>Quote</div>),
                //    Cell: row => <div className="font-weight-bold" style={{ textAlign: "center" }}>{row.value}</div>,
                //    accessor: "quoteId"
                //},
                //{
                //	Header: () => (<div style={{ textAlign: "center" }}>Order</div>),
                //	accessor: "quoteIds",
                //},
                //{
                //    Header: "QuoteID",
                //    accessor: "quoteIdButton",
                //    //filter: 'orderIdFilter',
                //    width: 100

                //},
                {
                    Header: "Quote",
                    accessor: "quote_id",
                    show: true,
                    Cell: cell => <div style={{
                        textAlign: "center",
                        //color: "#00f2c3" //"#8dc63f"
                    }}>{cell.value}</div>,
                    width: 75
                },
                {
                    Header: () => (<div style={{ textAlign: "center" }}>Station</div>),
                    Cell: cell => <div className="station-bold">{friendlyStationName(cell.value)}</div>,
                    accessor: "woprStation",
                },
                {
                    Header: "Title",
                    accessor: "title",
                    width: 200
                },
                {
                    Header: "Sales Person",
                    accessor: "salesPerson",
                },
                {
                    Header: "Created By",
                    accessor: "created_by",
                },
                {
                    Header: "Customer",
                    accessor: "customer",
                    width: 200
                },
                {
                    Header: "Ordered",
                    accessor: "orderedTime",
                    width: 100,
                    sortType: sortByDateOrderedTime
                },
                {
                    Header: "Approved",
                    accessor: "orderApprovedTime",
                    width: 100,
                    sortType: sortByDateApprovedTime
                },
                {
                    Header: "ETS Date",
                    accessor: "expectedShipDate",
                    Cell: cell => <div className={(Date.now() > new Date(cell.value)) && !cell.row.original.order_complete_time ? "text-danger warning-text-blink" : ""}>{cell.value}</div>,
                    width: 100
                },
                {
                    Cell: cell => <div className="cell-word-wrap cell-darkorange-text">{cell.value}</div>,
                    Header: "Delayed",
                    accessor: "reasonDelayed",
                },
                {
                    Cell: cell => <div className="italic">{cell.value}</div>,
                    Header: "Portal Notes",
                    accessor: "notes",
                },
                {
                    Cell: cell => <div className="cell-word-wrap cell-blue-text">{cell.value}</div>,
                    Header: "Mfg Notes",
                    accessor: "mfgNotes",
                },
                {
                    Header: "Shipments",
                    accessor: "shipmentsList",
                    width: 200,
                }
            ];

       

        if (!hideCompletedColumn) {
            myColumns.push(
            {
                Header: "Completed",
                accessor: "orderCompleteTime",
                sortType: sortByDateComplete,
               
            })
        }
        

        // add admin columns if appropriate
        //if (user.mfgApiRole.roleName == "admin") {
        if (true) {
            myColumns.push(
                {
                    Header: "Actions",
                    accessor: "actions",
                    width: 100
                });
        }
        return myColumns;
    }


  
    //
    // USE EFFECT
    //
    useEffect(() => {

        //grab the user object so we can restrict functions based on role
        

        // build table columns
        setTableColumns(buildColumns());

        populateOrders(hideCompleted);

        // Update data every 1 minute
        //const interval = setInterval(() => {
        //    if (!modal) {
        //        // use lambda expression so hideCompleted is actually updated. Otherwise, setInverval inside useEffect will 
        //        // always use the state of hideCompleted the first time that setInterval is ran
        //        populateOrders(hideCompleted => hideCompleted); 
        //    }
        //}, 60000 * 5);
        
       

        // Clean up on "dismount"
        //return () => clearInterval(interval);
    },
        []
    );

    //
    // RETURN VIEW
    //
    return (
        <>
            <div className="content">
                {/*Setup the Loading component*/}
                {loading ? (
                    <LoaderGears/>
                ) : null}
                <Row >
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle>
                                    <label>The last 6 months</label>
                                    <div className="pull-right">
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox"
                                                    onChange={(e) => {
                                                        console.log("ck", e.target.checked);
                                                        setHideCompleted(e.target.checked);
                                                        setTableColumns(buildColumns(e.target.checked));
                                                        populateOrders(e.target.checked);
                                                    }} />
                                                <span className="form-check-sign" />
                                                Hide Completed Orders
                                            </Label>
                                        </FormGroup>
                                    </div>
                                </CardTitle>
                                
                            </CardHeader>
                            <CardBody>
                                <ReactTableMfgOrderStatus id="reactTableMfgOrderStatus"
                                    data={orders}
                                    className="-striped -highlight"
                                    columns={tableColumns}
                                    getRowProps={row => {
                                        
                                        if (row) {
                                            return {
                                                style: {
                                                    background: row.original.submitted_to_mfg ? 'lightgray' : "",
                                                }
                                            }
                                        }
                                        return {}
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>


                {/*
				 * 
				 * Setup the Edit Modal Here
				 * 
				 */}

                <Modal isOpen={modal} toggle={toggleEditModal} fade={true} size="lg">
                    <Form onSubmit={handleSubmit}>
                        <ModalBody>

                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h3">Order {editOrder.quote_id}</CardTitle>
                                    <Label tag="h5"> {editOrder.title}</Label>
                                </CardHeader>
                                <CardBody>

                                    <FormGroup className="mt-3">
                                        <Label>Portal Notes</Label>
                                        <Input type="textarea"
                                            className="bigger-text-area"
                                            readOnly={true}
                                            defaultValue={editOrder.notes}
                                            onChange={(e) => setEditOrderObj({ notes: e.target.value })}
                                        />
                                    </FormGroup>


                                    <FormGroup className="mt-3">
                                        <Label>Reason Delayed</Label>
                                        <Input type="text"
                                            defaultValue={editOrder.reasonDelayed}
                                            onChange={(e) => setEditOrderObj({ reasonDelayed: e.target.value })}
                                            readOnly={user.mfgApiRole.roleName !== "admin"}
                                            //className="disabled-white-text"
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label>Expected Ship Date</Label>
                                        <ReactDatetime
                                            inputProps={{
                                                className: "form-control",
                                                placeholder: "Choose Expected Ship Date",
                                                disabled: user.mfgApiRole.roleName !== "admin" 
                                            }}
                                            dateFormat={"D MMM YYYY"}
                                            timeFormat={false}
                                            //onChange={(e) => setEditOrderObj({ expectedShipDate: e.target.value.toDate()})}
                                            onChange={updateDate}
                                            //defaultValue={editOrder.expectedShipDate}
                                            value={Common.convertDateToFriendlyDate(editOrder.expectedShipDate)}
                                            
                                        />
                                    </FormGroup>

                                    

                                    <FormGroup className="mt-3">
                                        <Label>Manufacturing Notes</Label>
                                        <Input
                                            className="bigger-text-area"
                                            type="textarea"                                           
                                            defaultValue={editOrder.mfgNotes}
                                            onChange={(e) => setEditOrderObj({ mfgNotes: e.target.value })}
                                            readOnly={user.mfgApiRole.roleName !== "admin"}
                                        />
                                    </FormGroup>

                                    {/*<FormGroup className="mt-3">*/}
                                    {/*		<Label>Expected Ship Date</Label>*/}
                                    {/*		<Input type="date">*/}
                                    {/*		defaultValue={editOrder.expectedShipDate}*/}
                                    {/*		onChange={(e) => setEditOrderObj({ expectedShipDate: e.target.value })}*/}
                                    {/*		</Input>		*/}
                                    {/*</FormGroup>*/}


                                    <FormGroup className="mt-3">
                                        <Label>Shipments</Label>
                                        <Table
                                            className="-striped -highlight"
                                            style={{ width: "600px" }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th className="header margin-20">Carrier</th>
                                                    <th className="header margin-20">Time</th>
                                                    <th className="header margin-20">Tracking Number</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                           
                                            {editOrder.shipments ? editOrder.shipments.map(s => {
                                                
                                                return (
                                                   
                                                    <tr>
                                                        <td>{s.carrier}</td>
                                                        <td>{Common.convertUnixDateToDateString(s.time)}</td>
                                                        <td>{s.tracking_number}</td>
                                                    </tr>
                                                )
                                            }) : null}
                                            </tbody>
                                        </Table>
                                    </FormGroup>


                                    <FormGroup check className="mt-3">
                                        <Label check>
                                            <Input type="checkbox" readOnly={user.mfgApiRole.roleName !== "admin"}/>
                                            <span className="form-check-sign" />
								Archive this Order
								</Label>
                                    </FormGroup>

                                </CardBody>
                                <CardFooter>
                                    {(user.mfgApiRole.roleName === "admin" && !editOrder.submitted_to_mfg) ? <Button className="btn-fill float-left" color="primary" onClick={toggleEditModal} type="submit">Submit</Button> : ""}
                                    <Button className="btn-fill float-right" color="secondary" onClick={toggleEditModal}>Cancel</Button>
                                </CardFooter>
                            </Card>
                        </ModalBody>
                    </Form>
                </Modal>

            </div>
        </>
    );
};



export default MfgOrderStatus;




// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({column: { filterValue, setFilter, preFilteredRows, id },})
{
    // Calculate the options for filtering
    // using the preFilteredRows
    const selectOptions = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })

        const selOptions = [];
        selOptions.push({ "value": "", "label": "all" });
        Array.from(options).map((option) => {
            if (option)
                selOptions.push({ "value": option.toString(), "label": option.toString() });
        });
        //console.log(selOptions);
        return selOptions;

    }, [id, preFilteredRows])

    
    // Render a multi-select box
    return (
        
        <Select
            
            className="react-select info"
            classNamePrefix="react-select"
            name="singleSelect"
            value={selectOptions[filterValue]}
            onChange={ value => {
                //console.log("onChange", value);
                setFilter(value.value || undefined)
            }}
            options={selectOptions}
           
        >
        </Select>
        
    )
}


//const OrderShipmentList = (props) =>
//{
//    React.useEffect(() => {
//        if (props.orderShipments.length > 0)
//            console.log("OrderShipmentList.orderShipments", props.orderShipments);
//    },
//        []
//    );

//    return (
//        <div>
//            <label>{props.orderShipments}</label>
//        {
//        props.orderShipments.map(s => {
//            <a
//                href={'https://google.com/search?q=' + s.tracking_number}
//                target="_blank">{s.carrier + " " + s.tracking_number}
//            </a>
//        })
//        }
//        </div>
//    );
//}