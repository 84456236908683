import React from "react";
import classNames from "classnames";

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Badge,
    Spinner,
    Button,
    UncontrolledAlert,
    Modal,
    Form,
    ModalBody,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import MfgApiService from "../MfgApiService";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import { couldStartTrivia, escapeLeadingUnderscores, forEachChild } from "typescript";
import EditOrderPriority from "./modals/EditOrderPriority";
import Common from "../utils/Common.js";
import LoaderGears from "../components/LoaderGears";


const ShadeCountBadge = (props) => {
    return (
        <div style={{
            fontSize: "small",
            fontWeight: "bolder",
            backgroundColor: "lightgray",
            borderRadius: "10px",
            borderStyle: "thin",
            width: "30px",
            /*position: "relative",*/
            float: "right",
            color: "black",
            margin: "auto",
            textAlign: "center",
            padding: "2px",
        }}>
            {props.shadeCount}
        </div>
    );
};




const BigBoard = () => {

    const [showArchived, setShowArchived] = React.useState(false);
    const [user, setUser] = React.useState(JSON.parse(localStorage.getItem("user")));
    const [isAdmin, setIsAdmin] = React.useState(user.mfgApiRole.roleName == "admin");
    const [orders, setOrders] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    
    const [stateProcessing, setStateProcessing] = React.useState([]);
    const [stateConfigControl, setStateConfigControl] = React.useState([]);
    const [stateInventory, setStateInventory] = React.useState([]);
    const [stateKitting, setStateKitting] = React.useState([]);
    const [stateSaw, setStateSaw] = React.useState([]);
    const [stateFabric, setStateFabric] = React.useState([]);
    const [stateAssembly, setStateAssembly] = React.useState([]);
    const [stateProvision, setStateProvision] = React.useState([]);
    const [stateShipping, setStateShipping] = React.useState([]);
    const [stateFile, setStateFile] = React.useState([]);

    const [statePriorities, setStatePriorities] = React.useState([]);
    const [stateToday, setStateToday] = React.useState([]);
    const [stateTomorrow, setStateTomorrow] = React.useState([]);


    const [alert, setAlert] = React.useState(null);
    const hideAlert = () => {
        setAlert(null);
    };
    const successAlert = (message) => {
        setAlert(
            <ReactBSAlert
                
                style={{ display: "block", marginTop: "-100px" }}
                title={message}
                onConfirm={() => hideAlert()}

                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel

                btnSize=""
            >
            </ReactBSAlert>
        );
    };

    const [errorAlertState, setErrorAlertState] = React.useState(null);
    const hideErrorAlert = () => {
        setErrorAlertState(null);
    };
    const errorAlert = (message) => {
        setErrorAlertState(
            <ReactBSAlert

                style={{ display: "block", marginTop: "-100px" }}
                title="Error!"
                content={message}
                onConfirm={() => hideErrorAlert()}
                confirmBtnBsStyle="danger"
                confirmBtnText="Dang Bro."
                btnSize=""
            >
            </ReactBSAlert>
        );
    };

    const handleEditModalSubmit = (obj) => {
        getData(showArchived);
    };

    const addPriority = (quote_id) => {
        ////let newPriorities = [...statePriorities];
        ////newPriorities.push(quote_id.toString());
        ////setStatePriorities(newPriorities);
        //let newPriorities = [...priodata];
        //newPriorities[0].push(quote_id.toString());
        
        //setData(newPriorities);
        getData(showArchived);
    }

    const [modal, setModal] = React.useState(false);
    const toggleEditModal = () => setModal(!modal);
    const [isNewItem, setIsNewItem] = React.useState(false);
    const [editItem, setEditItem] = React.useState({});

    const [priorityData, setPriorityData] = React.useState([[], [], []]);
    const getList = (id) => priorityData[id];

    


    const getData = (showTheArchives) => {
        setLoading(true);
        console.log("getData");

        let processing = [];
        let orders = [];
        let inventory = [];
        let kitting = [];
        let saw = [];
        let fabric = [];
        let assembly = [];
        let provision = [];
        let shipping = [];
        let file = []; //completed
        let modOrders = [];

        // only look at the last 3 months
        let date = new Date(); // now
        let startDate = new Date(date.setMonth(date.getMonth() - 3));
        let dtDate = Common.convertDateToDateTime(startDate);

    
        MfgApiService.get(`/api/orders/shades?startDate=${dtDate}`)
            .then(data => {
                
                data.map(o => {
            
                    // get then number of shades for each order
                    let numShades = 0;
                    o.shade_assemblies.map(sa => numShades += sa.shades.length);
                    let modOrder = { ...o, totalShades: numShades }
                    modOrders.push(modOrder);

                    if (modOrder.woprStation) {
                        //if (modOrder.woprStation.includes("MF"))
                        //    processing.push(modOrder.quote_id);
                        if (modOrder.woprStation.includes("IR"))
                            inventory.push(modOrder);
                        else if (modOrder.woprStation.includes("KT"))
                            kitting.push(modOrder);
                        else if (modOrder.woprStation.includes("SC"))
                            saw.push(modOrder);
                        else if (modOrder.woprStation.includes("FC"))
                            fabric.push(modOrder);
                        else if (modOrder.woprStation.includes("AS"))
                            assembly.push(modOrder);
                        else if (modOrder.woprStation.includes("PV"))
                            provision.push(modOrder);
                        else if (modOrder.woprStation.includes("SS"))
                            shipping.push(modOrder);
                        else if (modOrder.woprStation.includes("FI"))
                            file.push(modOrder);
                    }
                });

                //setStateProcessing(processing);
                
                setStateInventory(inventory);
                setStateKitting(kitting);
                setStateSaw(saw);
                setStateFabric(fabric);
                setStateAssembly(assembly);
                setStateProvision(provision);
                setStateShipping(shipping);
                setStateFile(file);
                setOrders(modOrders);



                // fill the priorities list
                MfgApiService.get("/api/orderpriorities")
                    .then(data => {
                        console.log("orderpriorities", data);

                        setStatePriorities(data);
                        let newPriorities = [...priorityData];

                        // look at the target date
                        let queue = [];
                        let today = [];
                        let tomorrow = [];

                        let dateToday = new Date();
                        let dateTomorrow = new Date();
                        dateTomorrow.setDate(dateToday.getDate() + 1);

                        data.map(item => {

                            // console.log("modOrders", modOrders);
                            // get then number of shades for each order
                            let quote_id = item.quoteId;
                            let myOrder = modOrders.find(o => { return o.quote_id === quote_id });
        
                            
                            //console.log("myOrder", myOrder);
                            item['totalShades'] = myOrder ? myOrder.totalShades : "err";
                            item['order_complete_time'] = myOrder ? myOrder.order_complete_time : null;
                            
                         
                            if (!item.archived || showTheArchives) {
                                if (item.targetProductionDay) {
                                    let targetDay = new Date(item.targetProductionDay);

                                    // if an order priority is not archived and has a target production date
                                    // less that todays date it needs to stay on the today's list.
                                    if (targetDay.getDate() <= dateToday.getDate()) {
                                        today.push(item);
                                    }
                                    else if (targetDay.getDate() == dateTomorrow.getDate()) {
                                        tomorrow.push(item);
                                    }
                                    else {
                                        queue.push(item);
                                    }
                                } else {
                                    queue.push(item);
                                }
                            }
                        });

                        // sort the lists by index. 0 is the hightest priority
                        queue = queue.sort((a, b) => (a.targetProductionDayIndex > b.targetProductionDayIndex) ? 1 : -1)
                        today = today.sort((a, b) => (a.targetProductionDayIndex > b.targetProductionDayIndex) ? 1 : -1)
                        tomorrow = tomorrow.sort((a, b) => (a.targetProductionDayIndex > b.targetProductionDayIndex) ? 1 : -1)

                        newPriorities[0] = queue;
                        newPriorities[1] = today;
                        newPriorities[2] = tomorrow;
                        setPriorityData(newPriorities);

                       // console.log("todayList", today);
                        setLoading(false);
                    });


            });


       
    };


    function onDragEnd(result) {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        //convert id to number
        const sInd = +source.droppableId;
        const dInd = +destination.droppableId;

        let newState = [...priorityData];

        if (sInd === dInd) {
            const items = reorder(priorityData[sInd], source.index, destination.index);

            newState[sInd] = items;
      
        } else {
            const newResult = move(
                priorityData[sInd],
                priorityData[dInd],
                source,
                destination
            );
            
            newState[source.droppableId] = newResult[sInd];
            newState[dInd] = newResult[dInd];
            
        }

        // update the database here
        // Queue List
        newState[0].map((item, index) => {
            console.log("map", item);
            item.TargetProductionDayIndex = index;
            item.TargetProductionDay = null;
            MfgApiService.put('/api/orderpriorities/' + item.id.toString(), item)
                .then(data => { console.log("today", data) })
                .catch(error => { console.error(error) });

        });

        // Today List
        newState[1].map((item, index) => {
            console.log("map", item);
            item.TargetProductionDayIndex = index;
            item.TargetProductionDay = Common.convertDateToDateTime(Date.now()); // TODAY
            MfgApiService.put('/api/orderpriorities/' +  item.id.toString(), item)
                .then(data => { console.log("today", data) })
                .catch(error => {console.error(error)});

        });

        // Tomorrow List
        newState[2].map((item, index) => {
            console.log("map", item);
            item.TargetProductionDayIndex = index;

            let dateTomorrow = new Date();
            dateTomorrow.setDate(dateTomorrow.getDate() + 1);

            item.TargetProductionDay = Common.convertDateToDateTime(dateTomorrow);
            MfgApiService.put('/api/orderpriorities/' + item.id.toString(), item)
                .then(data => { console.log("tomorrow", data) })
                .catch(error => { console.error(error) });

        });

        setPriorityData(newState);
    }


    React.useEffect(() => {


        getData(showArchived);
        const interval = setInterval( () => {
            getData(showArchived);
        }, 60000 * 5); // update every 5 minutes

        // clean up
        return () => clearInterval(interval);
    },[]);

    // Return the component
    return (<>
        <div className="content">

            {/*Setup the Alerts Here*/}
            {errorAlertState}

            {/*Setup the Edit Modal*/}
            <EditOrderPriority
                isOpen={modal} toggle={toggleEditModal} handleSubmit={handleEditModalSubmit} isNewItem={isNewItem} item={editItem}
            ></EditOrderPriority>

            {/*Setup the Loading component*/}
            {loading ? (
                <LoaderGears/>
            ) : null}

            {/*Main View of Component*/}
            <Row>
                <Col xs="2">
                    {isAdmin ?
                        <>
                            <div className="pull-left">
                                <Button className="btn btn-primary"
                                    onClick={() => {
                                        setIsNewItem(true);
                                        setEditItem({});
                                        setModal(true);
                                        console.log("addPriority");
                                    }}
                                >
                                    Add Order To Queue
                                </Button>

                            </div>
                        </>
                        : null}
                </Col>
                <Col xs="2">
                    <div className="pull-left">
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox"
                                    onChange={(e) => {
                                        console.log("ck", e.target.checked);
                                        setShowArchived(e.target.checked);
                                        getData(e.target.checked);
                                    }} />
                                <span className="form-check-sign" />
                                Show Archived
                                </Label>
                        </FormGroup>
                    </div>
                </Col>   
                <Col xs="4"></Col>
                <Col xs="4">
                    {/*<div className="d-flex justify-content-center">*/}
                    {/*<h1>BIG BOARD</h1>*/}
                    {/*</div>*/}
                </Col>
            </Row>
            <Row>
                <Col xl="6">
                    <Row>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Col xs="4"><PriorityCard droppableId="0" data={priorityData} setData={setPriorityData} propClass="btn-success" stationName="Queue" isAdmin={isAdmin} handleSubmit={handleEditModalSubmit} /></Col>
                            <Col xs="4"><PriorityCard droppableId="1" data={priorityData} setData={setPriorityData} propClass="btn-success" stationName="Today" isAdmin={isAdmin} handleSubmit={handleEditModalSubmit} /></Col>
                            <Col xs="4"><PriorityCard droppableId="2" data={priorityData} setData={setPriorityData} propClass="btn-success" stationName="Tomorrow" isAdmin={isAdmin} handleSubmit={handleEditModalSubmit} /></Col>
                        </DragDropContext>
                    </Row>
                </Col>
                <Col xl="6">
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h3">WOPR LOCATION
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                        <Row>    
                            <Col xs="3"><StationCard data={stateInventory}  propClass="btn-warning" stationName="Inventory" /></Col>
                            <Col xs="3"><StationCard data={stateKitting}  propClass="btn-success" stationName="Kitting" /></Col>
                            <Col xs="3"><StationCard data={stateSaw}  propClass="btn-danger" stationName="Saw Cut Station" /></Col>
                            <Col xs="3"><StationCard data={stateFabric} propClass="btn-info" stationName="Fabric Station" /></Col>
                        </Row>
                        <Row>      
                            <Col xs="3"><StationCard data={stateAssembly}  propClass="btn-danger" stationName="Assembly Station" /></Col>
                            <Col xs="3"><StationCard data={stateProvision}  propClass="btn-primary" stationName="Provisioning Station" /></Col>
                            <Col xs="3"><StationCard data={stateShipping}  propClass="btn-info" stationName="Shipping Station" /></Col>
                            <Col xs="3"><StationCard data={stateFile} propClass="btn-success" stationName="Complete" /></Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
               
            </Row>
        </div>
        </>);
};




const StationCard = (props) => {

    const handleClick = (o) => {

        if (props.handleClick) {
            props.handleClick(o.quote_id);
            console.log(o)
        }
    }

    return (
        <Card className={classNames("card-big-board-station", props.complete ? "card-big-board-complete" : "card-big-board")}>
            <CardHeader>
            <CardTitle>
                <h3 className="title d-inline">{props.stationName}</h3>
            </CardTitle>           
            </CardHeader>
            <CardBody>
               
                <div>
                    {
                        props.data ? props.data.map((o) => {
                            return(
                                <Button
                                    
                                    data={o}
                                    key={o.quote_id}
                                    className={classNames("btn-simple btn-big-board", props.propClass)}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleClick(o);
                                    }
                                    }>
                                    <ShadeCountBadge shadeCount={o.totalShades} />
                                    {o.quote_id} 
                                </Button>
                            )
                        }):null
                    }
                </div>
               
            </CardBody>
        </Card>
        
    );
};











const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {

    console.log(source);

    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};


const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    //background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
});
const getListStyle = isDraggingOver => ({
    //background: isDraggingOver ? "silver" : "lightgrey",
    padding: grid,
    border: "1 1 1 1"
});





const PriorityCard = (props) => {
   
    const [modal, setModal] = React.useState(false);
    const toggleEditModal = () => setModal(!modal);
    const [isNewItem, setIsNewItem] = React.useState(false);
    const [editItem, setEditItem] = React.useState({});

    const handleEditModalSubmit = () => {
        props.handleSubmit();
    };

    const [alert, setAlert] = React.useState(null);
    const hideAlert = () => {
        setAlert(null);
    };
    const successAlert = (message) => {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title={message}
                onConfirm={() => hideAlert()}

                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, delete it!"
                cancelBtnText="Cancel"
                showCancel

                btnSize=""
            >               
            </ReactBSAlert>
        );
    };


    return (

        <Card style={{boxShadow:"inset"}} className="card-big-board card-big-board-priority">

            <EditOrderPriority
                isOpen={modal} toggle={toggleEditModal} handleSubmit={handleEditModalSubmit} isNewItem={isNewItem} item={editItem}
            ></EditOrderPriority>

            {alert}

            <CardHeader>
                <CardTitle>
                    <h3 className="title d-inline">{props.stationName}</h3>
                   
                </CardTitle>
            </CardHeader>
            <CardBody>
                    <Droppable droppableId={props.droppableId} >
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                            {props.data[+props.droppableId].length ? props.data[+props.droppableId].map((item, index) => (
                                <Draggable key={item.quoteId} draggableId={item.quoteId.toString()} index={index} isDragDisabled={!props.isAdmin}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                            <div className="bb-alert-primary bb-alert-simple">

                                               
                                                <Row>
                                                    <Col style={{padding:"0"}}>
                                                        <h3 style={{
                                                            margin: "2px 2px 2px 2px"
                                                        }}>{item.quoteId}</h3>
                                                    </Col>
                                                    <Col>
                                                        <ShadeCountBadge shadeCount={item.totalShades} />
                                                    </Col>
                                                    <Col>
                                                         <Row>
                                                            {item.order_complete_time ? <p className="text-success" >Complete</p> : null}
                                                        </Row>
                                                        <Row>
                                                            {item.archived ? <p className="text-info" >Archived</p> : null}
                                                        </Row>
                                                    </Col>        
                                                </Row>
                                                {props.isAdmin ?
                                                    <Row>

                                                        {/* use this button to remove the data row */}
                                                        {/*<Button*/}
                                                        {/*    onClick={(e) => {*/}
                                                        {/*        e.preventDefault();*/}
                                                        {/*        successAlert("Are you sure you want to delete this priority???");*/}
                                                        {/*    }}*/}
                                                        {/*    color="danger"*/}
                                                        {/*    size="sm"*/}
                                                        {/*    className={classNames("btn-icon btn-simple pull-right",)}*/}
                                                        {/*>*/}
                                                        {/*    <i className="tim-icons icon-simple-remove" />*/}
                                                        {/*</Button>*/}

                                                        {/* use this button to edit the data row */}
                                                        <Button
                                                            id={item.quoteId}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setIsNewItem(false);
                                                                setEditItem(item);
                                                                setModal(true);
                                                                console.log("edit priority");
                                                            }}
                                                            color="info"
                                                            size="sm"
                                                            className="btn-icon btn-simple pull-right"
                                                        >
                                                            <i className="tim-icons icon-pencil" />
                                                            
                                                        </Button>


                                                    </Row> : null
                                                    }
                                                    <Row>
                                                        <label style={{
                                                            whiteSpace: "nowrap",
                                                            width: "auto",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "inline-block"
                                                        }}>
                                                        {item.order.mfgNotes}
                                                        </label>         
                                                    </Row>
                                                </div>
                                               
                                            </div>
                                        )}
                                    </Draggable>
                                )) : null}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
            </CardBody>
        </Card>
    );
};

export default BigBoard;