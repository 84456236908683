import React, { Component } from 'react';
//import authService from 'components/api-authorization/AuthorizeService';

// reactstrap components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Row,
    Col
} from "reactstrap";

import ReactTable from "components/ReactTable/ReactTable.js";
import mfgApiService from '../MfgApiService';
import Common from 'utils/Common.js';

export class FetchShipments extends Component {
    static displayName = FetchShipments.name;

    constructor(props) {
        super(props);
        this.state = { shipments: [], loading: true };
    }

    componentDidMount() {
        this.populateShipmentsData();
    }

    static renderShipmentsTable(shipments) {
        return (

            <div className="content">
                <Col md={8} className="ml-auto mr-auto">
                    <h2 className="text-center">UPS WorldShip Shipments</h2>
                </Col>
                <Row className="mt-5">
                    <Col xs={12} md={12}>
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Shipments</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <ReactTable className='table table-striped' aria-labelledby="tabelLabel"
                                    data={shipments}
                                    columns={[
                                        //{
                                        //    Header: "Id",
                                        //    accessor: "id",
                                        //},
                                        //{
                                        //    Header: "Order Id",
                                        //    accessor: "ordersId",
                                        //},
                                        {
                                            // Note: might make this Carrier dependant link.
                                            // For example UPS https://www.ups.com/track?loc=null&tracknum=1Z9V2W940348108692&requester=WT/trackdetails
                                            Header: "Tracking Number",
                                            accessor: "trackingNumber",
                                            Cell: row => <div><a href={'https://google.com/search?q=' + row.value} target="_blank">{row.value}</a></div>,
                                        },
                                        {
                                            Header: "Order From Shipment",
                                            accessor: "orderFromShipment",
                                        },
                                        {
                                            Header: "Carrier",
                                            accessor: "carrier",
                                        },
                                        {
                                            Header: "Ship Date",
                                            accessor: "shipDate",
                                            Cell: row => <div>{Common.convertDateToFriendlyDate(row.value)}</div>,
                                        },
                                        {
                                            Header: "Shipping First Name",
                                            accessor: "shipping_first_name",
                                        },
                                        {
                                            Header: "Shipping Company",
                                            accessor: "shipping_company",
                                        },
                                        {
                                            Header: "Shipping Country",
                                            accessor: "shipping_country",
                                        },
                                        {
                                            Header: "Shipping State",
                                            accessor: "shipping_state",
                                        },
                                       
                                    ]}
                                >
                                </ReactTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : FetchShipments.renderShipmentsTable(this.state.shipments);

        return (
            <div className="content">
                {/*<h1 id="tabelLabel" >PowerShades Orders</h1>*/}
                {/*<p>This component demonstrates fetching data from the server.</p>*/}
                {contents}
            </div>
        );
    }

    async populateShipmentsData() {
        //const token = await authService.getAccessToken();

        //const token = JSON.parse(localStorage.getItem("access_token"));
        //const response = await fetch('api/shipments', {
        //    headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        //});

        //if (response.status != 401) {
        //    const data = await response.json();
        //    this.setState({ shipments: data, loading: false });
        //}

        await mfgApiService.get('/api/shipments')
            .then(data => {
                console.log('Success!', data);
                if (data != null) {
                    this.setState({ shipments: data, loading: false });
                }
            })
            .catch(error => console.error("error", error));
    }
}

